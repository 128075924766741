import React from "react";
import "../Styles/AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutuscontainer">
      <h1>About Us</h1>
      <h3>Introduction</h3>
      <p>
        Welcome to VOGA, your ultimate destination for trendy and affordable
        men's fashion. At VOGA, we believe that every man deserves to look and
        feel his best, no matter his style or budget. With a wide range of
        clothing, accessories, and footwear, we strive to empower men to express
        themselves through fashion, confidence, and individuality.
      </p>

      <h3>Our Mission</h3>
      <p>
        At VOGA, our mission is simple: to provide men with fashionable and
        high-quality clothing at affordable prices. We aim to inspire confidence
        and self-expression by offering a diverse selection of clothing and
        accessories that cater to different tastes, body types, and lifestyles.
        Through our curated collections and exceptional customer service, we
        strive to create a seamless shopping experience for every man who visits
        our website.
      </p>

      <h3>Our Vision</h3>
      <p>
        We envision VOGA as more than just a clothing brand. We aspire to be a
        community where men can come together to celebrate their uniqueness and
        embrace their personal style. By fostering a supportive and inclusive
        environment, we hope to empower men to embrace their individuality and
        express themselves boldly through fashion.
      </p>

      <h3>Our Products</h3>
      <p>
        At VOGA, we take pride in offering a wide range of products that cater
        to every aspect of a man's wardrobe. From stylish shirts and trousers to
        comfortable loungewear and sleek accessories, our collection is designed
        to suit every occasion and preference. Whether you're dressing for a
        casual day out or a special event, you'll find the perfect ensemble to
        elevate your look at VOGA.
      </p>
      <p style={{ margin: "5px 0", lineHeight: "22px" }}>
        <b>Trendy Clothing:</b> Discover the latest trends in men's fashion with
        our curated selection of clothing. From timeless classics to
        fashion-forward pieces, we have everything you need to stay stylish and
        on-trend.
      </p>
      <p style={{ margin: "5px 0", lineHeight: "22px" }}>
        <b>Accessorize Your Look:</b> Complete your outfit with our stylish
        accessories, including statement watches, fashionable belts, and trendy
        wallets. Our accessories are designed to add the perfect finishing touch
        to any ensemble.
      </p>
      <h3>Our Commitment to Quality</h3>
      <p>
        At VOGA, we are committed to providing our customers with high-quality
        products that exceed their expectations. We carefully source our
        materials and work with trusted suppliers to ensure that every item
        meets our rigorous standards of quality and craftsmanship. From the
        stitching to the fabric, attention to detail is paramount in everything
        we do.
      </p>
      <h3>Sustainability and Ethical Practices</h3>
      <p>
        As a responsible fashion brand, we are committed to sustainability and
        ethical practices throughout our supply chain. We strive to minimize our
        environmental impact by using eco-friendly materials, reducing waste,
        and supporting ethical manufacturing practices. Additionally, we
        prioritize fair labor practices and ensure that all workers involved in
        the production of our garments are treated with dignity and respect.
      </p>
      <h3>Our Customer Promise</h3>
      <p>
        At VOGA, our customers are at the heart of everything we do. We are
        dedicated to providing exceptional customer service and ensuring that
        every shopping experience is enjoyable and hassle-free. Whether you have
        a question about sizing, need assistance with an order, or simply want
        style advice, our friendly and knowledgeable team is here to help.
      </p>
      <h3>Join the VOGA Community</h3>
      <p>
        We invite you to join the VOGA community and experience the joy of
        shopping for men's fashion in a supportive and inclusive environment.
        Follow us on social media for style inspiration, exclusive offers, and
        updates on our latest collections. We can't wait to help you unleash
        your inner fashionista and discover your unique style with VOGA.
      </p>
      <h3>Conclusion</h3>
      <p>
        Thank you for choosing VOGA as your go-to destination for men's fashion.
        We are honored to be a part of your style journey and look forward to
        helping you look and feel your best every step of the way. Shop with us
        today and experience the VOGA difference!
      </p>
    </div>
  );
};

export default AboutUs;
