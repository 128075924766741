import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import caraousel_1 from "../Assets/caraousel_1.jpg";
import caraousel_2 from "../Assets/caraousel_2.jpg";
import caraousel_3 from "../Assets/caraousel_3.jpg";
import caraousel_4 from "../Assets/caraousel_4.jpg";
import caraousel_5 from "../Assets/caraousel_5.jpg";

import image_banner from "../Assets/image_banner1.jpg";

const Home = () => {
  const [currentItemIndex, setCurrentItemIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const newArrivals = [
    {
      id: 1,
      title: "Black Polo Tshirt For Men",
      salesPrice: 1399,
      BuyPrice: 2999,
      discount: 53,
      category: "Polo Tshirt",
      image:
        "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
    },
    {
      id: 2,
      title: "Black Polo Tshirt For Men",
      salesPrice: 1399,
      BuyPrice: 2999,
      discount: 53,
      category: "Polo Tshirt",
      image:
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
    },
    {
      id: 3,
      title: "Black Polo Tshirt For Men",
      salesPrice: 1399,
      BuyPrice: 2999,
      discount: 53,
      category: "Polo Tshirt",
      image:
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
    },
    {
      id: 4,
      title: "Black Polo Tshirt For Men",
      salesPrice: 1399,
      BuyPrice: 2999,
      discount: 53,
      category: "Polo Tshirt",
      image:
        "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
    },
    {
      id: 5,
      title: "Black Polo Tshirt For Men",
      salesPrice: 1399,
      BuyPrice: 2999,
      discount: 53,
      category: "Polo Tshirt",
      image:
        "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
    },
  ];

  const originalCarouselItems = [
    { type: "image", src: caraousel_1 },
    { type: "image", src: caraousel_2 },
    { type: "image", src: caraousel_3 },
    { type: "image", src: caraousel_4 },
    { type: "image", src: caraousel_5 },
  ];

  const carouselItems = [
    originalCarouselItems[originalCarouselItems.length - 1],
    ...originalCarouselItems,
    originalCarouselItems[0],
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTransitioning && !isHovered) {
        setCurrentItemIndex((prevIndex) => prevIndex + 1);
        setIsTransitioning(true);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isTransitioning, isHovered]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    if (currentItemIndex === 0) {
      setCurrentItemIndex(carouselItems.length - 2);
    } else if (currentItemIndex === carouselItems.length - 1) {
      setCurrentItemIndex(1);
    }
  };

  useEffect(() => {
    const carouselInner = document.querySelector(".carouselInner");
    carouselInner.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carouselInner.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentItemIndex, carouselItems.length]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    const touchMove = (moveEvent) => {
      const touchEndX = moveEvent.touches[0].clientX;
      const difference = touchStartX - touchEndX;
      if (Math.abs(difference) > 50) {
        if (difference > 0 && currentItemIndex < carouselItems.length - 1) {
          setCurrentItemIndex(currentItemIndex + 1);
        } else if (difference < 0 && currentItemIndex > 0) {
          setCurrentItemIndex(currentItemIndex - 1);
        }
      }
    };
    const touchEnd = () => {
      document.removeEventListener("touchmove", touchMove);
      document.removeEventListener("touchend", touchEnd);
    };
    document.addEventListener("touchmove", touchMove);
    document.addEventListener("touchend", touchEnd);
  };

  return (
    <div
      className="homeMain"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="carousel-container">
        <div
          className="carouselInner"
          style={{
            transform: `translateX(-${currentItemIndex * 100}%)`,
            transition: isTransitioning ? "transform 1s ease-in-out" : "none",
          }}
          onTouchStart={handleTouchStart}
        >
          {carouselItems.map((item, index) => (
            <img
              key={index}
              src={item.src}
              alt={`carousel-${index}`}
              className="carouselImage"
            />
          ))}
        </div>
      </div>
      <ProductCarouselContainer data={newArrivals} title={"NEW ARRIVALS"} />
      <div className="imageBanner">
        <img src={image_banner} alt="image_banner" />
      </div>
      <h2>TRENDING STYLES</h2>
      <div className="collectionsOptions">
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/plain_new_update_images_2_5_2022/seafoam_green_plain_t-shirt_base_29_07_2023_700x933.jpg"
            alt="images"
          />
          <p>PLAIN TEES</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/thunder_grey_polo_t-shirt_base_01_05_2024_700x933.jpg"
            alt="images"
          />
          <p>POLO TEES</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pink_plain_oversized_t-shirt_for_men_side_view15_01_2024_700x933.jpg"
            alt="images"
          />
          <p>OVERSIZED TEES</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/full_sleeves_new_update_images/plain_olive_green_full_sleeves_t-shirt_hover_29_12_2022_400x533.jpg"
            alt="images"
          />
          <p>FULL SLEEVES TEES</p>
        </div>
      </div>
      <ProductCarouselContainer
        data={newArrivals}
        title={"YOUR RECENT FAVES"}
      />
    </div>
  );
};

const ProductCarouselContainer = ({ data, title }) => {
  return (
    <div className="newArrivalsContainer">
      <h2>{title}</h2>
      <div className="newArrivalProductContainer">
        {data.map((product) => (
          <div className="newArrivalProduct">
            <div className="newArrivalProductTop">
              <img src={product.image} alt="img" />
            </div>
            <div className="newArrivalProductBottom">
              <p className="title_">{product.title}</p>
              <p className="category_">{product.category}</p>
              <p className="price_">
                <b>₹{product.salesPrice}</b> <s>₹{product.BuyPrice}</s>{" "}
                <span>({product.discount}% off)</span>
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="viewAllBtn">VIEW ALL</div>
    </div>
  );
};

export default Home;
